import type { RouteRecordRaw } from "vue-router"
import { callRegisterVerify, callInvitationVerify } from "@/auth/api/auth-api"
import {
  isTokenExpiredGuard,
  isTokenInQueryGuard,
  validateTokenGuard,
} from "@/auth/utils/route-guards"
import LayoutBlank from "@/commons/layouts/LayoutBlank.vue"
import { CommonRoutesDefinitions } from "@/commons/routes"
import { bookDemoGuard } from "./utils/route-guards"

export const AuthRoutes = {
  LOGIN: { name: "auth/login" },
  REGISTRATION: { name: "auth/registration" },
  REGISTRATION_SUCCESS: { name: "auth/registration/success" },
  REGISTRATION_VERIFY: { name: "auth/registration/verify" },
  REGISTRATION_VERIFY_EXPIRED: { name: "auth/registration/verify/expired" },
  PASSWORD_REMINDER: { name: "auth/password-reminder" },
  PASSWORD_REMINDER_SUCCESS: { name: "auth/password-reminder/success" },
  PASSWORD_RESET: { name: "auth/password-reminder/reset" },
  PASSWORD_RESET_SUCCESS: { name: "auth/password-reminder/reset/success" },
  PASSWORD_RESET_EXPIRED: { name: "auth/password-reminder/reset/expired" },
  BOOK_DEMO: { name: "auth/book-demo" },
  // Organization invites
  INVITATION_VERIFY: { name: "auth/invitation/verify" },
  INVITATION_EXPIRED: { name: "auth/invitation/expired" },
  // Platform invites - without booking demo
  INVITATION_LINK: { name: "auth/invitation/link" },
} as const

export const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: AuthRoutes.LOGIN.name,
    component: () => import("./views/ViewAuthLogin.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/registration",
    name: AuthRoutes.REGISTRATION.name,
    component: () => import("./views/ViewAuthRegistration.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/registration/success",
    name: AuthRoutes.REGISTRATION_SUCCESS.name,
    component: () => import("./views/ViewAuthRegistrationSuccess.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/registration/verify",
    name: AuthRoutes.REGISTRATION_VERIFY.name,
    component: () => import("./views/ViewAuthRegistrationVerify.vue"),
    beforeEnter: [
      isTokenInQueryGuard({ redirectTo: CommonRoutesDefinitions.ERROR }),
      validateTokenGuard({
        validator: (token) => callRegisterVerify(token),
        redirectTo: AuthRoutes.REGISTRATION_VERIFY_EXPIRED,
      }),
      // Redirect to book demo page after successful registration verification
      (to, from, next) => {
        if (to.query.skipBookDemo === "true") {
          next({ ...AuthRoutes.LOGIN, query: { verified: "true" } })
          return
        }
        next(AuthRoutes.BOOK_DEMO)
      },
    ],
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/registration/verify/expired",
    name: AuthRoutes.REGISTRATION_VERIFY_EXPIRED.name,
    component: () => import("./views/ViewAuthRegistrationVerifyExpired.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/password-reminder",
    name: AuthRoutes.PASSWORD_REMINDER.name,
    component: () => import("./views/ViewAuthPasswordReminder.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/password-reminder/success",
    name: AuthRoutes.PASSWORD_REMINDER_SUCCESS.name,
    component: () => import("./views/ViewAuthPasswordReminderSuccess.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/password-reset",
    name: AuthRoutes.PASSWORD_RESET.name,
    component: () => import("./views/ViewAuthPasswordReset.vue"),
    beforeEnter: [
      isTokenInQueryGuard({
        redirectTo: {
          ...CommonRoutesDefinitions.ERROR,
          state: { message: "Invalid password reset link" },
        },
      }),
      isTokenExpiredGuard({ redirectTo: AuthRoutes.PASSWORD_RESET_EXPIRED }),
    ],
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/password-reset/success",
    name: AuthRoutes.PASSWORD_RESET_SUCCESS.name,
    component: () => import("./views/ViewAuthPasswordResetSuccess.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/password-reset/expired",
    name: AuthRoutes.PASSWORD_RESET_EXPIRED.name,
    component: () => import("./views/ViewAuthPasswordResetExpired.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/book-demo",
    name: AuthRoutes.BOOK_DEMO.name,
    component: () => import("./views/ViewAuthBookDemo.vue"),
    beforeEnter: [bookDemoGuard({ redirectTo: CommonRoutesDefinitions.DEFAULT })],
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/invitation/verify",
    name: AuthRoutes.INVITATION_VERIFY.name,
    component: () => import("./views/ViewAuthInvitationAccept.vue"),
    beforeEnter: [
      isTokenInQueryGuard({ redirectTo: CommonRoutesDefinitions.ERROR }),
      isTokenExpiredGuard({ redirectTo: AuthRoutes.INVITATION_EXPIRED }),
      validateTokenGuard({
        validator: (token) => callInvitationVerify(token),
        redirectTo: AuthRoutes.INVITATION_EXPIRED,
      }),
    ],
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/invitation/expired",
    name: AuthRoutes.INVITATION_EXPIRED.name,
    component: () => import("./views/ViewAuthInvitationExpired.vue"),
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
  {
    path: "/invitation/link",
    name: AuthRoutes.INVITATION_LINK.name,
    component: () => import("./views/ViewAuthInvitationLink.vue"),
    beforeEnter: [
      isTokenInQueryGuard({ redirectTo: CommonRoutesDefinitions.ERROR }),
      isTokenExpiredGuard({ redirectTo: AuthRoutes.INVITATION_EXPIRED }),
    ],
    meta: {
      layout: LayoutBlank,
      isUnauthorized: true,
    },
  },
]
